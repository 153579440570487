import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { Spin } from "antd";

// import MainTemplate from "./../templates/mainTemplate";
// import Home from "./../screens/home";
// import PreLoginTemplate from "./../templates/preloginTemplate";
// import Opportunities from "../screens/opportunities/opportunities";
// import Resources from "../screens/resources/resources";
// import Analytics from "../screens/analytics/analytics";
// import ProjectDetails from "../screens/opportunities/projectDetails";
// import Compare from "../screens/opportunities/compare";
// import Sectors from "../screens/sectors/sectors";
// import NotFound from "../screens/notFound";
// import Login from "../screens/auth/login";
// import Register from "../screens/auth/register";
// import AddProject from "../screens/opportunities/addProject";
// import AddPromoter from "../screens/opportunities/addPromoter";
// import SectorDetail from "../screens/sectors/sectorDetail";
// import AdminPanel from "../screens/admin/admin-panel/adminPanel";
// import Scheme from "../screens/admin/admin-panel/scheme";
// import AdminTemplate from "../templates/adminTemplate";
// import AllSectors from "../screens/opportunities/allSectors";
// import PlanCategory from "../screens/admin/admin-panel/planCategory";
// import Details from "../screens/admin/admin-panel/details";
// import UserDetails from "../screens/admin/admin-panel/userDetails";
// // import InvestorDetails from "../screens/admin/admin-panel/investorDetails";
// import CategoryDetail from "../screens/category-detail/categoryDetail";
// import InvestorProfile from "../screens/profileSteps/profileStepper";
// import Profile from "../screens/profileSteps/profile";
// import ConfirmPass from "../screens/auth/confirmPass";
// import Forgot from "../screens/auth/forgot";
// import ProtectedRoute from "./protectedRoute";
// import InvestorFavProjects from "../screens/opportunities/investorFavProjects";
// import Reminders from "../screens/admin/admin-panel/reminders";
// import Diligence from "../screens/admin/admin-panel/diligence";

// import SectorsInfo from "../screens/admin/admin-panel/sectorInfo";
// import DisplaySectors from "../screens/admin/admin-panel/displaySectors";
// import ReportDetail from "../screens/admin/admin-panel/reportDetail";
// import GenerateReport from "../screens/admin/admin-panel/generateReport";
// import GenerateInvestorReport from "../screens/admin/admin-panel/generateInvestorReport";
// import Testimonials from "../screens/admin/admin-panel/testimonials";

// import ForgotPassword from "../screens/auth/forgotPassword";
// import Verification from "../screens/verification";
// import SubSectorDetail from "../screens/sectors/subSectorDetail";
// import Blacklist from "../screens/admin/admin-panel/blacklist";
// import SchemesDetail from "../screens/schemes/schemesDetail";

const MainTemplate = React.lazy(() => import("./../templates/mainTemplate"));
const Home = React.lazy(() => import("./../screens/home"));
const PreLoginTemplate = React.lazy(() =>
  import("./../templates/preloginTemplate")
);
const Opportunities = React.lazy(() =>
  import("../screens/opportunities/opportunities")
);
const Resources = React.lazy(() => import("../screens/resources/resources"));
const Analytics = React.lazy(() => import("../screens/analytics/analytics"));
const ProjectDetails = React.lazy(() =>
  import("../screens/opportunities/projectDetails")
);
const Compare = React.lazy(() => import("../screens/opportunities/compare"));
const Sectors = React.lazy(() => import("../screens/sectors/sectors"));
const NotFound = React.lazy(() => import("../screens/notFound"));
const Login = React.lazy(() => import("../screens/auth/login"));
const Register = React.lazy(() => import("../screens/auth/register"));
const AddProject = React.lazy(() =>
  import("../screens/opportunities/addProject")
);
const AddPromoter = React.lazy(() =>
  import("../screens/opportunities/addPromoter")
);
const SectorDetail = React.lazy(() =>
  import("../screens/sectors/sectorDetail")
);
const AdminPanel = React.lazy(() =>
  import("../screens/admin/admin-panel/adminPanel")
);
const Scheme = React.lazy(() => import("../screens/admin/admin-panel/scheme"));
const AdminTemplate = React.lazy(() => import("../templates/adminTemplate"));
const AllSectors = React.lazy(() =>
  import("../screens/opportunities/allSectors")
);
const PlanCategory = React.lazy(() =>
  import("../screens/admin/admin-panel/planCategory")
);
const Details = React.lazy(() =>
  import("../screens/admin/admin-panel/details")
);
const UserDetails = React.lazy(() =>
  import("../screens/admin/admin-panel/userDetails")
);
// const InvestorDetails = React.lazy(() => import("../screens/admin/admin-panel/investorDetails"));
const CategoryDetail = React.lazy(() =>
  import("../screens/category-detail/categoryDetail")
);
const InvestorProfile = React.lazy(() =>
  import("../screens/profileSteps/profileStepper")
);
const Profile = React.lazy(() => import("../screens/profileSteps/profile"));
const ConfirmPass = React.lazy(() => import("../screens/auth/confirmPass"));
const Forgot = React.lazy(() => import("../screens/auth/forgot"));
const ProtectedRoute = React.lazy(() => import("./protectedRoute"));
const InvestorFavProjects = React.lazy(() =>
  import("../screens/opportunities/investorFavProjects")
);
const Reminders = React.lazy(() =>
  import("../screens/admin/admin-panel/reminders")
);
const Diligence = React.lazy(() =>
  import("../screens/admin/admin-panel/diligence")
);

const SectorsInfo = React.lazy(() =>
  import("../screens/admin/admin-panel/sectorInfo")
);
const DisplaySectors = React.lazy(() =>
  import("../screens/admin/admin-panel/displaySectors")
);
const ReportDetail = React.lazy(() =>
  import("../screens/admin/admin-panel/reportDetail")
);
const GenerateReport = React.lazy(() =>
  import("../screens/admin/admin-panel/generateReport")
);
const GenerateInvestorReport = React.lazy(() =>
  import("../screens/admin/admin-panel/generateInvestorReport")
);
const Testimonials = React.lazy(() =>
  import("../screens/admin/admin-panel/testimonials")
);
const Blacklist = React.lazy(() =>
  import("../screens/admin/admin-panel/blacklist")
);

const ForgotPassword = React.lazy(() =>
  import("../screens/auth/forgotPassword")
);
const Verification = React.lazy(() => import("../screens/verification"));
const SubSectorDetail = React.lazy(() =>
  import("../screens/sectors/subSectorDetail")
);
const SchemesDetail = React.lazy(() =>
  import("../screens/schemes/schemesDetail")
);

const MainRouter = () => {
  /**
   * checkForAllowedMenuAs in protected route is to check if its allowed in allowed menu list received in login response
   * ***/
  return (
    <Suspense
      fallback={
        <div>
          <div id="skeleton">
            <Spin size="large" />
          </div>
        </div>
      }
    >
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <MainTemplate path="/" component={Home} exact />}
        />
        <Route
          exact
          path="/category-detail/:id"
          render={(props) => (
            <MainTemplate
              path="/category-detail/:id"
              component={CategoryDetail}
              exact
            />
          )}
        />
        <Route
          exact
          path="/verification"
          render={(props) => (
            <MainTemplate path="/verification" component={Verification} exact />
          )}
        />
        <ProtectedRoute
          exact
          path="/dashboard"
          component={AdminPanel}
          Template={AdminTemplate}
          // render={(props) => <AdminTemplate path="/dashboard" component={AdminPanel} exact />}
        />
        <ProtectedRoute
          exact
          path="/dashboard/scheme"
          component={Scheme}
          Template={AdminTemplate}
          // render={(props) => <AdminTemplate path="/dashboard/scheme" component={Scheme} exact />}
        />
        <ProtectedRoute
          exact
          path="/dashboard/plan-category"
          component={PlanCategory}
          Template={AdminTemplate}

          // render={(props) => <AdminTemplate path="/dashboard/plan-category" component={PlanCategory} exact />}
        />
        <ProtectedRoute
          exact
          path="/dashboard/details"
          component={Details}
          Template={AdminTemplate}
          // render={(props) => <AdminTemplate path="/dashboard/details" component={Details} exact />}
        />
        <ProtectedRoute
          exact
          path="/dashboard/user-details"
          component={UserDetails}
          Template={AdminTemplate}

          // render={(props) => <AdminTemplate path="/dashboard/user-details" component={UserDetails} exact />}
        />
        <ProtectedRoute
          exact
          path="/dashboard/reminders"
          component={Reminders}
          Template={AdminTemplate}

          // render={(props) => <AdminTemplate path="/dashboard/user-details" component={UserDetails} exact />}
        />

        <ProtectedRoute
          exact
          path="/dashboard/sectors-info"
          component={SectorsInfo}
          Template={AdminTemplate}
        />

        <ProtectedRoute
          exact
          path="/dashboard/display-sectors"
          component={DisplaySectors}
          Template={AdminTemplate}
        />

        <ProtectedRoute
          exact
          path="/dashboard/report-detail"
          component={ReportDetail}
          Template={AdminTemplate}
        />
        <ProtectedRoute
          exact
          path="/dashboard/generate-project-report"
          component={GenerateReport}
          Template={AdminTemplate}
        />

        <ProtectedRoute
          exact
          path="/dashboard/generate-investor-report"
          component={GenerateInvestorReport}
          Template={AdminTemplate}
        />

        <ProtectedRoute
          exact
          path="/dashboard/diligence"
          component={Diligence}
          Template={AdminTemplate}
        />

        <ProtectedRoute
          exact
          path="/dashboard/blacklist"
          component={Blacklist}
          Template={AdminTemplate}
        />

        <ProtectedRoute
          exact
          path="/dashboard/testimonials"
          component={Testimonials}
          Template={AdminTemplate}
        />

        {/* <Route
        exact
        path="/dashboard/investor-details"
        render={(props) => <AdminTemplate path="/dashboard/investor-details" component={InvestorDetails} exact />}
      /> */}
        <Route
          exact
          path="/login"
          render={(props) => (
            <PreLoginTemplate path="/login" component={Login} exact />
          )}
        />

        <Route
          exact
          path="/forgot"
          render={(props) => (
            <PreLoginTemplate path="/forgot" component={Forgot} exact />
          )}
        />

        {/* <Route
        exact
        path="/forgot-password"
        component={ForgotPassword}
      /> */}

        {/* set forgotPassword screen in prelogin template*/}
        <Route
          exact
          path="/forgot-password"
          render={(props) => (
            <PreLoginTemplate
              path="/forgot-password"
              component={ForgotPassword}
              exact
            />
          )}
        />

        <Route
          exact
          path="/register"
          render={(props) => (
            <PreLoginTemplate path="/register" component={Register} exact />
          )}
        />
        <Route
          exact
          path="/opportunities"
          render={(props) => (
            <MainTemplate
              path="/opportunities"
              component={Opportunities}
              exact
            />
          )}
        />
        <Route
          exact
          path="/opportunities/sectors"
          render={(props) => (
            <MainTemplate
              path="/opportunities/sectors"
              component={AllSectors}
              exact
            />
          )}
        />
        <Route
          exact
          path="/scheme/:name"
          render={(props) => (
            <MainTemplate
              path="/scheme/:name"
              component={SchemesDetail}
              exact
            />
          )}
        />
        <ProtectedRoute
          exact
          path="/opportunity/add-project/:id?"
          Template={MainTemplate}
          component={AddProject}

          // render={(props) => (
          //   <MainTemplate
          //     path="/opportunity/add-project"
          //     component={AddProject}
          //     exact
          //   />
          // )}
        />
        <ProtectedRoute
          exact
          path="/opportunity/add-promoter/:id?"
          Template={MainTemplate}
          component={AddPromoter}
        />
        <Route
          exact
          path="/opportunities/project-details/:id"
          // component={ProjectDetails}
          // Template={MainTemplate}
          render={(props) => (
            <MainTemplate
              path="/opportunities/project-details/:id"
              component={ProjectDetails}
              exact
            />
          )}
        />
        <Route
          exact
          path="/opportunities/compare"
          render={(props) => (
            <MainTemplate
              path="/opportunities/compare"
              component={Compare}
              exact
            />
          )}
        />
        <Route
          exact
          path="/analytics"
          render={(props) => (
            <MainTemplate path="/analytics" component={Analytics} exact />
          )}
        />
        <Route
          exact
          path="/resources"
          render={(props) => (
            <MainTemplate path="/resources" component={Resources} exact />
          )}
        />
        <Route
          exact
          path="/sectors"
          render={(props) => (
            <MainTemplate path="/sectors" component={Sectors} exact />
          )}
        />
        <Route
          exact
          path="/sector/detail/:id"
          render={(props) => (
            <MainTemplate
              path="/sector/detail/:id"
              component={SectorDetail}
              exact
            />
          )}
        />
        <Route
          exact
          path="/sector/detail/:id/:name"
          render={(props) => (
            <MainTemplate
              path="/sector/detail/:id/:name"
              component={SubSectorDetail}
              exact
            />
          )}
        />
        <ProtectedRoute
          exact
          path="/investor-profile/:id?"
          Template={MainTemplate}
          component={InvestorProfile}
          // render={(props) => (
          //   <MainTemplate path="/investor-profile/:id?" component={InvestorProfile} exact />
          // )}
        />
        <ProtectedRoute
          exact
          path="/investor-projects"
          Template={MainTemplate}
          component={InvestorFavProjects}
          // render={(props) => (
          //   <MainTemplate path="/investor-profile/:id?" component={InvestorProfile} exact />
          // )}
        />
        <ProtectedRoute
          exact
          path="/profile/:id?"
          component={Profile}
          Template={MainTemplate}
          // render={(props) => (
          //   <MainTemplate path="/profile" component={Profile} exact />
          // )}
        />
        <Route exact path="/404" component={NotFound} />
        <Route
          exact
          path="/change-password"
          render={(props) => (
            <MainTemplate
              path="/change-password"
              component={ConfirmPass}
              exact
            />
          )}
        />

        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
};

export default MainRouter;
