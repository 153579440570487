import React, { Fragment, useState, useEffect } from 'react'
import ChatMessages from './chatMessages';
import ChatUsersList from './chatUsersList';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getChatUser } from '../../actions/project';
import { fetchMessages, messageSend } from '../../services/helper.service';
import chatIcon from "../../media/live-chat-icon.png"

const Chat = () => {
    const [open, setOpen] = useState(false);
    const [toggle, setToggle] = useState(false);
    const dispatch = useDispatch();
    const isChatId = useSelector(state => state.global.chatId);
    const [messages, setMessages] = useState([]);
    const [file, setFile] = useState();
    const [users, setUsers] = useState([]);

    const { chatUsers } = useSelector((state) => state.global || [], shallowEqual);
    const companyId = useSelector((state) => state.auth?.user?.data?.company_id, shallowEqual);
    const [chatId, setChatId] = useState("");
    const [selectedUser,setSelectedUser] = useState("");


    const closeChatBox = () => {

        if (isChatId) {

            dispatch({ type: "CHAT_ID", payload: "" })
        }
        setOpen(!open);


    }
    useEffect(() => {
        if (!isChatId) {

            setOpen(false);
        }
        else {
            setOpen(true);

        }
    }, [isChatId])
    useEffect(() => {
        if (chatUsers && chatUsers?.length > 0) {
            let users = chatUsers?.map(element => element.usersData.find(i => i.companyId !== companyId));
            // console.log(users)
            setUsers(users);
        }
        if(isChatId){
            
            let user = chatUsers?.find(i => i.chatId === isChatId);
           
            let a = user?.usersData?.find(i=>i.companyId !== companyId);
           
            if(a){
            setSelectedUser(a?.companyFullName ? a?.companyFullName: `${a.companyFirstName} ${a?.companyLastName}`  );
            }
        }
    }, [chatUsers,isChatId])

    useEffect(() => {
        if (isChatId) {
            getMessages(isChatId)
            dispatch(getChatUser());
        }

    }, [isChatId])

    const getMessages = async (chatId) => {
        try {
            let req = {
                chatId
            }
            const res = await fetchMessages(req);

            if (res.status === "200") {
                // console.log(res);

                setChatId(chatId)
                setMessages(res.data);        
                setToggle(true);

            }
        } catch (error) {

        }
    }
    const sendMessage = async (message) => {

        if (!message.trim()) return


        try {
            let req = {
                chatId,
                message,
                fileFor: "CHAT",

            }
            if (file) {
                req[`${file.name}`] = file
            }

            const res = await messageSend(req);
            if (res.status === "201") {
                // console.log(res);
                getMessages(chatId);
                if (file) {
                    setFile("");
                }

            }
        } catch (error) {

        }
    }

    const fileChange = (file) => {
        setFile(file.file)
    }


    return (
        <Fragment>
            {(open || isChatId) ?
                <Fragment>
                    {toggle ?
                        <ChatMessages file={file} toggle={toggle} setToggle={setToggle}
                            messages={messages} fileChange={fileChange}
                            sendMessage={sendMessage} selectedUser={selectedUser}/>

                        :
                        <ChatUsersList open={open} users={users} setSelectedUser={setSelectedUser}
                            getMessages={getMessages} closeChatBox={closeChatBox}/>
                    }
                </Fragment>
                : null
            }
            <div className="live-chat d-flex align-items-center justify-content-between" onClick={() => closeChatBox()}>
                <p className="mb-0">
                    Messaging
                </p>
                <span>
                    <img src={chatIcon} alt=""/>
                </span>
            </div>
        </Fragment>
    )
}

export default Chat;

