import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import { HashRouter } from "react-router-dom";
import "./fonts/LatoLatin-Bold.woff";
import "./fonts/LatoLatin-Regular.woff";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";

// store.subscribe(() => {
//    saveState(store.getState().auth);
//   // throttle( () => saveState(store.getState().auth), 100)
// }, 500);
ReactDOM.render(
  <HashRouter basename="/">
    <Provider store={store}>
      <App />
    </Provider>
  </HashRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
