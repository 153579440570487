import { Card, Comment, Upload } from 'antd';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { SendOutlined } from "@ant-design/icons";
import { Empty } from 'antd';
import { MdOutlineDownloadForOffline } from "react-icons/md"
import { AiOutlineFile } from "react-icons/ai"
import { IoIosArrowBack } from "react-icons/io"
import sendChat from "../../media/send-chat-icon.png"
import attachFile from "../../media/attach-file-chat.png"
import { getWord } from '../../helpers/globalFunctions';


const API_URL = process.env.REACT_APP_API_URL;;

const ChatMessages = ({ selectedUser, file, setToggle, toggle, messages, sendMessage, fileChange }) => {

    const [message, setMessage] = useState("");
    const user = useSelector((state) => state || [], shallowEqual);
    const id = user?.auth?.user?.data?.company_id;
    const userData = user?.auth?.user?.data;
    const msglist = useRef('');
    useEffect(() => {

        if (msglist.current) {
            msglist.current.scrollTop = msglist.current.scrollHeight;
        }
    }, [messages])
    return (
        <Fragment>
            <div className="avatar-title-wrapper" >
                <Card
                    className="user-chat-box"
                    title={selectedUser || ""}
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        right: 20,
                        zIndex: 11,
                        background: 'white',
                        width: 380,
                        borderRadius: 15,

                    }}
                >
                    <div className="d-flex align-items-center avatar-initials">
                        <div className="chat-back-wrap">
                            {toggle && <div onClick={() => setToggle(false)}><IoIosArrowBack size={20} className="chat-back-icon" /></div>}
                        </div>
                        <div className="avatar avatar-top ms-2">
                            <div className="avatar-letters">

                                {getWord(selectedUser)}

                            </div>
                        </div>
                    </div>

                    <div className="message-wrapper" ref={msglist}>
                        {messages && messages.length > 0 && messages.map((msg, index) => (
                            < div className="chat-list-wrapper" key={index}>
                                <Comment
                                    className=""
                                    content={
                                        <Fragment>
                                            {msg.senderId === id ?
                                                <div className="avatar avatar-user-reciver">
                                                    <div className="avatar-letters">
                                                        {getWord(
                                                            userData?.fullName ? userData?.fullName : `${userData.firstName} ${userData?.lastName}`
                                                        )}
                                                    </div>
                                                </div>
                                                :
                                                <div className="avatar avatar-user-sender">
                                                    <div className="avatar-letters">
                                                        {getWord(selectedUser)}
                                                    </div>
                                                </div>

                                            }
                                            <span className={`${msg.senderId === id ? 'chat-user-reciver' : 'chat-user-sender'}`}>

                                                <span>
                                                    {msg?.messageFileName &&
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <span className="d-flex align-items-center justify-content-between">
                                                                <AiOutlineFile className="me-1" style={{ fontSize: 22, color: `${msg.senderId !== id ? "white" : "#000"}` }} />
                                                                <span className={`${msg.senderId !== id ? "file-name text-white" : "file-name "}`}
                                                                >{msg?.messageFileName}
                                                                </span>
                                                            </span>
                                                            <MdOutlineDownloadForOffline
                                                                onClick={() => window.location.href = `${API_URL}/file/download/${msg?.messegeFile}`}
                                                                style={{ fontSize: 24, color: `${msg.senderId !== id ? "white" : "#000"}` }}

                                                            />
                                                        </div>
                                                    }
                                                    {msg?.message}
                                                </span>
                                                <span className={`${msg.senderId !== id ? 'text-white chat-time' : 'chat-time'}`} >{moment(msg?.createdAt).format('hh:mm A')}</span>
                                            </span>
                                        </Fragment>
                                    }
                                />
                            </div>
                        ))}
                        {messages.length === 0 && <div>
                            <Empty />
                        </div>}
                    </div>
                    {file?.name && <div className="append-file">
                        {file?.name}
                    </div>}
                    <div className="chat-box">

                        <textarea
                            rows={1}
                            onChange={(e) => setMessage(e.target.value)}
                            className="chat-input"
                            name='message'
                            type={'text'}
                            value={message || ""}
                            placeholder={"Write your message here"}
                            onKeyUp={(e) => { if (e.code === "Enter") { sendMessage(message); setMessage("") } }}
                        />
                        <div className="d-none justify-content-between align-items-center">

                            <SendOutlined className="chat-sending-icons" style={{ fontSize: 20, color: "gray" }} />
                        </div>

                        <Upload className="chat-sending-icons" onChange={fileChange} beforeUpload={'false'}
                            maxCount={1} multiple={false} showUploadList={false}>
                            <img src={attachFile} alt="" className="chat-send-icons-left" />
                        </Upload>

                        <img src={sendChat} onClick={() => { setMessage(""); sendMessage(message) }}
                            alt="" className="chat-send-icons-right" />

                    </div>
                </Card >

            </div>

        </Fragment>

    )
}

export default ChatMessages;