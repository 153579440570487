import {
    GET_CONFIGURATION_SUCCESS,
    GET_CONFIGURATION_FAIL,
  } from "../actions/types";


  const initialState = {
    dollar: {
      value:1,
      exists:false
    }
  };

  export default function config(state = initialState, action) {

    const { type, payload } = action;
    switch (type) {
      case GET_CONFIGURATION_SUCCESS:
        if(payload.config.title === 'USDollar') {        
          return {
            ...state,
            dollar: payload.config,
          };
        }
        break;
      case GET_CONFIGURATION_FAIL:
        return {
            ...state,
        };
      default:
        return state;
    }
  }