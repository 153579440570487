import {
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  GET_CONFIGURATION_SUCCESS,
  GET_CONFIGURATION_FAIL,
  GET_SECTOR_DETAILS_SUCCESS,
  GET_SECTOR_DETAILS_FAIL,
  SET_MESSAGE, GET_PROVINCE_SUCCESS, GET_PROVINCE_FAIL,
  GET_ROLES_SUCCESS, GET_COUNTRY_SUCCESS,
  GET_ROLES_FAIL, GET_COUNTRY_FAIL, GET_STATS_FAIL, GET_STATS_SUCCESS, GET_SECTOR_SUCCESS, GET_SECTOR_FAIL,
  GET_PLAN_CATEGORY_FAIL,
  GET_PLAN_CATEGORY_SUCCESS,
  GET_SCHEME_FAIL,
  GET_SCHEME_SUCCESS,
  GET_FAV_SUCCESS,
  GET_FAV_FAIL,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_DASHBOARD_PROJECTS_FAIL,
  GET_DASHBOARD_PROJECTS_SUCCESS,
  GET_PLAN_CATEGORIES_SUCCESS,
  GET_PLAN_CATEGORIES_FAIL,
  GET_ALL_SECTOR_SUCCESS,
  GET_ALL_SECTOR_FAIL,
  GET_CHAT_USER_SUCCESS,
  GET_CHAT_USER_FAIL,
  LOGOUT,
  GET_BLACKLIST_SUCCESS,
  GET_BLACKLIST_FAIL,
  GET_TESTIMONIAL_FAIL,
  GET_TESTIMONIAL_SUCCESS
} from "./types";
import projectService from "../services/project.service";


export const getProject = (req) => (dispatch) => {
  return projectService.getProjects(req).then(
    (response) => {
      if (response.status === '200') {
        dispatch({
          type: GET_PROJECTS_SUCCESS,
          payload: { project: response },
        });
        // dispatch({
        //   type: SET_MESSAGE,
        //   payload: response.data.message,
        // });
        // return Promise.resolve();
      }
      else {
        // const message = response.data;
        // dispatch({
        //   type: GET_PROJECTS_FAIL,
        // });
        // dispatch({
        //   type: SET_MESSAGE,
        //   payload: message,
        // });
        // return Promise.reject();
      }
      return Promise.resolve();
    },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      // if (error?.response?.status === 403) {
      //   console.log(error?.response);
      //   // message.error(error?.response?.data);
      //   // localStorage.clear();
      //   // window.location.assign("/");
      // }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      if (error?.response?.status === 500) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        window.location.assign("/pmis#/login");
      }
      // const message =
      //   (error.response &&
      //     error.response.data &&
      //     error.response.data.message) ||
      //   error.message ||
      //   error.toString();

      // dispatch({
      //   type: GET_PROJECTS_FAIL,
      // });

      // dispatch({
      //   type: SET_MESSAGE,
      //   payload: message,
      // });

      return Promise.reject();
    }
  );
};
export const getUser = (req) => (dispatch) => {
  return projectService.getUsers(req).then(
    (response) => {
      if (response.status === '200') {
        dispatch({
          type: GET_USERS_SUCCESS,
          payload: { users: response },
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        return Promise.resolve();
      }
      else {
        const message = response.data;
        dispatch({
          type: GET_USERS_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      if (error?.response?.status === 500) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        window.location.assign("/pmis#/login");
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_PROJECTS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getPlanCategory = (req) => (dispatch) => {
  return projectService.getPlanCategory(req).then(
    (response) => {
      if (response.status === '200') {
        dispatch({
          type: GET_PLAN_CATEGORY_SUCCESS,
          payload: { planCategories: response },
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        return Promise.resolve();
      }
      else {
        const message = response.data;
        dispatch({
          type: GET_PLAN_CATEGORY_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_PLAN_CATEGORY_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getScheme = (req) => (dispatch) => {
  return projectService.getSchemes(req).then(
    (response) => {


      if (response.status === '200') {
        dispatch({
          type: GET_SCHEME_SUCCESS,
          payload: { schemes: response.data },
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        return Promise.resolve();
      }
      else {
        const message = response.data;
        dispatch({
          type: GET_SCHEME_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    },
    (error) => {

      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_SCHEME_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getConfigutaion = (req) => (dispatch) => {
  return projectService.getConfiguration(req).then((response) => {
    if (response.statusCode === 200) {
      dispatch({
        type: GET_CONFIGURATION_SUCCESS,
        payload: { config: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_CONFIGURATION_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_CONFIGURATION_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getSectorDetails = (req) => (dispatch) => {
  return projectService.getSectorDetails(req).then((response) => {

    if (response.status === '200') {
      dispatch({
        type: GET_SECTOR_DETAILS_SUCCESS,
        payload: { data: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_SECTOR_DETAILS_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_SECTOR_DETAILS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getRoles = () => (dispatch) => {
  return projectService.getRoles().then((response) => {


    if (response.status === '200') {
      dispatch({
        type: GET_ROLES_SUCCESS,
        payload: { roles: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_ROLES_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ROLES_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getCountry = () => (dispatch) => {
  return projectService.getCountries().then((response) => {

    if (response.status === '200') {
      dispatch({
        type: GET_COUNTRY_SUCCESS,
        payload: { country: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_COUNTRY_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_COUNTRY_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getProvince = () => (dispatch) => {
  return projectService.getProvince().then((response) => {

    if (response.status === '200') {
      dispatch({
        type: GET_PROVINCE_SUCCESS,
        payload: { province: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_PROVINCE_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_COUNTRY_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getStats = () => (dispatch) => {

  return projectService.getStats().then((response) => {


    if (response.status === '200') {
      dispatch({
        type: GET_STATS_SUCCESS,
        payload: { stats: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_STATS_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_STATS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getSectors = () => (dispatch) => {

  return projectService.getSectors().then((response) => {


    if (response.status === '200') {

      dispatch({
        type: GET_SECTOR_SUCCESS,
        payload: { sectors: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_SECTOR_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_STATS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getAllSectors = () => (dispatch) => {

  return projectService.getAllSectors().then((response) => {


    if (response.status === '200') {

      dispatch({
        type: GET_ALL_SECTOR_SUCCESS,
        payload: { allSectors: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_ALL_SECTOR_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_STATS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getBlacklist = () => (dispatch) => {

  return projectService.getBacklist().then((response) => {


    if (response.status === '200') {

      dispatch({
        type: GET_BLACKLIST_SUCCESS,
        payload: { blacklist: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_BLACKLIST_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_STATS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getFav = () => (dispatch) => {

  return projectService.getFav().then((response) => {


    if (response.status === '200') {

      dispatch({
        type: GET_FAV_SUCCESS,
        payload: { fav: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });
      
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_FAV_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      
    }
    return Promise.resolve();
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      // if (error?.response?.status === 403) {
      //   console.log(error?.response);
      //   // message.error(error?.response?.data);
      //   localStorage.clear();
      //   // window.location.assign("/");
      // }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      if (error?.response?.status === 500) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_STATS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getChatUser = () => (dispatch) => {

  return projectService.getChatUser().then((response) => {


    if (response.status === '200') {

      dispatch({
        type: GET_CHAT_USER_SUCCESS,
        payload: { chatUsers: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_CHAT_USER_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      // if (error?.response?.status === 403) {
      //   console.log(error?.response);
      //   // message.error(error?.response?.data);
      //   localStorage.clear();
      //   // window.location.assign("/");
      // }
      if (error?.response?.status === 500) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        window.location.assign("/pmis#/login");

      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_STATS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getCategories = () => (dispatch) => {

  return projectService.getCategories().then((response) => {


    if (response.status === '200') {

      dispatch({
        type: GET_PLAN_CATEGORIES_SUCCESS,
        payload: { categories: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_PLAN_CATEGORIES_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_STATS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getTestimonials = () => (dispatch) => {

  return projectService.getTestimonials().then((response) => {


    if (response.status === '200') {

      dispatch({
        type: GET_TESTIMONIAL_SUCCESS,
        payload: { testimonials: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_TESTIMONIAL_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_STATS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}
export const getDashboardProjects = () => (dispatch) => {

  return projectService.getDashboardProjects().then((response) => {


    if (response.status === '200') {

      dispatch({
        type: GET_DASHBOARD_PROJECTS_SUCCESS,
        payload: { dashboardProjects: response.data },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.message,
      });
      return Promise.resolve();
    }
    else {
      const message = response.data;
      dispatch({
        type: GET_DASHBOARD_PROJECTS_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  },
    (error) => {
      if (error?.response?.status === 401) {
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 403) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        localStorage.clear();
        // window.location.assign("/");
      }
      if (error?.response?.status === 409) {
        console.log(error?.response);
        // message.error(error?.response?.data);
        // localStorage.clear();
        dispatch({
          type: LOGOUT,
          payload: { user: null },
        });
        // window.location.assign("/pmis#/login");

      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_STATS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    })
}