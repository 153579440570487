export const projectVariant = {
    hidden: {
        y: 50,
        opacity: 0,

    },
    visible: {
        y: 0,
        opacity: 1,
        // transition: {
        //     type: 'tween',
        // }
    }
}
export const chatVariant = {
    hidden: {
        // miny: 50,
        y:400,
        // opacity: 0,

    },
    visible: {
        y: 0,
        // opacity: 1,
        transition: {
            type: 'tween',
            duration:.5
        }
    }
}
export const zoomVariant = {
    hidden: {
        y: 50,
        opacity: 0,
        scale: 0,
    },
    visible: {
        y: 0,
        scale: 1,
        opacity: 1,
        // transition: {
        //     type: 'tween',
        // }
    }
}

export const leftVariant = {
    hidden: {
        x: -50,
        opacity: 0
    },
    visible: {
        x: 0,
        opacity: 1,

        transition: {
            type: 'tween',
            duration: 1,

        },

    }
}
export const rightVariant = {
    hidden: {
        x: 50,
        opacity: 0
    },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 1,
        },

    }
}
export const topVariant = {
    hidden: {
        y: -50,
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 1,
        },

    }
}
export const bottomVariant = {
    hidden: {
        y: 50,
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 1,
        },

    }
}
export const filterDropDown = {
    hidden: {
        top: 0,
        opacity: 0,
        display:"none",
        transition: {
            type: 'tween',
            duration: 1,
        },
    },
    visible: {
        top: 163,
        opacity: 1,
        // visiblity:"visible",
        display:"block",
        transition: {
            type: 'tween',
            duration: 1,
        },

    }
}
export const mapVariant = {
    hidden: {
        pathLength: 0,
        // opacity: 0
        // stroke:'#F7D900',
    },
    visible: {
        pathLength: 1,
        strokeWidth:2,
        // stroke:'#F7D900',
        transition: {
            type: 'tween',
            duration: 4, ease: "easeInOut"
        },
       
        // opacity: 1,       
        // transition: {
        //     type: 'tween',
        //     duration: 1,           
        // },

    }
}

