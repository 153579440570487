import authHeader from './auth.header';
import http from "./httpServices";

const API_URL = process.env.REACT_APP_API_URL;;

const getProjects = async (req) => {


  return await http.get(API_URL + '/projects',
    { headers: authHeader() }).then((response) => {
      if (response.status !== '200') {
        return {}
      }

      return response;
    });
}
const getUsers = async (req) => {
  return await http.get(API_URL + '/users/getAllUsers',
    { headers: authHeader() }).then((response) => {
      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}


const getProvince = () => {
  return http.get(API_URL + '/lookup/getProjectLocation',
    { headers: authHeader() }).then((response) => {

      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}

const getConfiguration = async (req) => {
  return await http.post(API_URL + '/lookup/get-configuration-value', req,
    { headers: authHeader() }).then((response) => {
      const { data } = response;
      if (data.statusCode !== 200) {
        return {}
      }
      return data;
    });
}


const getRoles = async () => {
  return await http.get(API_URL + '/lookup/roles',
    { headers: authHeader() }).then((response) => {

      if (response.status !== '200') {
        return {}
      }

      return response;
    });
}


const getCountries = async () => {
  return await http.get(API_URL + '/lookup/country',
    { headers: authHeader() }).then((response) => {

      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}


const getSectorDetails = async () => {
  return await http.get(API_URL + '/projects/sectorsdetail',
    { headers: authHeader() }).then((response) => {
      const { data } = response;
      if (response.status !== '200') {
        return {}
      }
      return data;
    });
}
const getStats = async () => {
  return await http.get(API_URL + '/analytics/dashboardStats',
    { headers: authHeader() }).then((response) => {


      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}
const getSectors = async () => {
  return await http.get(API_URL + '/projects/sectorsdetail',
    { headers: authHeader() }).then((response) => {


      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}
const getAllSectors = async () => {
  return await http.get(API_URL + '/lookup/getsectors',
    { headers: authHeader() }).then((response) => {


      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}
const getBacklist = async () => {
  return await http.get(API_URL + '/lookup/blacklist',
    { headers: authHeader() }).then((response) => {


      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}
const getFav = async () => {
  return await http.get(API_URL + '/projects/getFavorities',
    { headers: authHeader() }).then((response) => {


      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}
const getCategories = async () => {
  return await http.get(API_URL + '/config/getCategories',
    { headers: authHeader() }).then((response) => {


      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}


/********* Plan Category **********/

const getPlanCategory = async () => {
  return await http.get(API_URL + '/config/getCategories',
    { headers: authHeader() }).then((response) => {

      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}
const getTestimonials = async () => {
  return await http.post(API_URL + '/config/get/testimonial',{},
    { headers: authHeader() }).then((response) => {

      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}


/********* Scheme **********/

const getSchemes = async () => {
  return await http.get(API_URL + '/config/getSchemes',
    { headers: authHeader() }).then((response) => {

      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}
const getDashboardProjects = async () => {
  return await http.get(API_URL + '/projects/dashboardProjects',
    { headers: authHeader() }).then((response) => {

      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}
const getChatUser = async (req) => {

  return await http.post(API_URL + `/chat/getUserChats`, req,
    {
      headers: authHeader(),

    }).then((response) => {

      if (response.status !== '200') {
        return {}
      }
      return response;
    });
}






const logger = {
  getProjects,
  getProvince,
  getStats,
  getConfiguration,
  getSectorDetails,
  getRoles,
  getCountries,
  getSectors,
  getAllSectors,
  getPlanCategory,
  getSchemes,
  getFav,
  getChatUser,
  getCategories,
  getUsers,
  getDashboardProjects,
  getBacklist,
  getTestimonials
}
export default logger;