import authHeader from './auth.header';
import http from './httpServices';

const API_URL = process.env.REACT_APP_API_URL;


const fetchInvestModelData = async () => {

    return await http.get(API_URL + '/lookup/getprojectinvestmentmodel',
        {
            headers: authHeader(),

        })
}
const fetchSectorsData = async () => {

    return await http.get(API_URL + '/lookup/getsectors',
        {
            headers: authHeader(),

        })
}
const fetchInvestTypeData = async () => {

    return await http.get(API_URL + '/lookup/getprojectinvestmenttype',
        {
            headers: authHeader(),

        })
}
const fetchProvinceStats = async (req) => {

    return await http.post(API_URL + '/projects/provincecount', req,
        {
            headers: authHeader(),

        })
}
const fetchProvinceStatsGpp = async (req) => {

    return await http.post(API_URL + '/projects/provincecountGPP', req,
        {
            headers: authHeader(),

        })
}
const getSubSectors = async () => {

    return await http.get(API_URL + '/lookup/getsubsectors',
        {
            headers: authHeader(),

        })
}
const getSectors = async () => {

    return await http.get(API_URL + '/lookup/getsectors',
        {
            headers: authHeader(),

        })
}
const geProjectSize = async () => {

    return await http.get(API_URL + '/lookup/projectSize',
        {
            headers: authHeader(),

        })
}
const geCountries = async () => {

    return await http.get(API_URL + '/lookup/country',
        {
            headers: authHeader(),

        })
}
const getprojectLocation = async () => {

    return await http.get(API_URL + '/lookup/getprojectlocation',
        {
            headers: authHeader(),

        })
}

const fetchSectorDetails = async () => {

    return await http.get(API_URL + '/projects/sectorsdetail',
        {
            headers: authHeader(),

        })
}
const getLocationDistrict = async (id) => {

    return await http.get(API_URL + `/lookup/getprojectDistrict/${id}`,
        {
            headers: authHeader(),

        })
}
const getFeasibilityStatus = async (id) => {

    return await http.get(API_URL + `/lookup/feasibilitystatus`,
        {
            headers: authHeader(),

        })
}
const getDevelopmentGoals = async () => {

    return await http.get(API_URL + `/lookup/developmentgoals`,
        {
            headers: authHeader(),

        })
}
const getPrivateProjectTypes = async () => {

    return await http.get(API_URL + `/lookup/privateProjectType`,
        {
            headers: authHeader(),

        })
}
const getPrivateProjectCategory = async () => {

    return await http.get(API_URL + `/lookup/privateProjectCategory`,
        {
            headers: authHeader(),

        })
}
const fetchProject = async (values) => {

    return await http.get(API_URL + `/projects/draft`,
        {
            headers: authHeader(),

        })
}
const fetchSingleProject = async (id) => {

    return await http.get(API_URL + `/project/${id}`,
        {
            headers: authHeader(),

        })
}
const getSubSectorsDataById = async (id) => {

    return await http.get(API_URL + `/projects/sectorsDetailById/${id}`,
        {
            headers: authHeader(),

        })
}
const uploadProject = async (values) => {

    return await http.post(API_URL + `/projects/upload`, values,
        {
            headers: authHeader(),

        })
}
const fetchProjects = async (values) => {

    return await http.get(API_URL + `/projects`,
        {
            headers: authHeader(),

        })
}
const fetchProjectDetail = async (id) => {

    return await http.get(API_URL + `/project/${id}`,
        {
            headers: authHeader(),

        })
}
const ProjectViewInc = async (req) => {

    return await http.post(API_URL + `/projects/visited`, req,
        {
            headers: authHeader(),

        })
}

const updateProjectStatus = async (req) => {

    return await http.post(API_URL + `/project/setProjectStatus`, req,
        {
            headers: authHeader(),

        })
}
const updateMarqueeStatus = async (req) => {

    return await http.post(API_URL + `/project/setMarquee`, req,
        {
            headers: authHeader(),

        })
}
const verifyAccount = async (req) => {

    return await http.post(API_URL + `/auth/verifyAccount`, req,
        {
            headers: authHeader(),

        })
}
const UploadFile = async (req, val) => {


    var data = new FormData();
    for (const i in val) {
        data.append(i, val[i]);
    }



    return await http.post(API_URL + `/file/upload`, data,
        {
            headers: authHeader()

        }).then(res => console.log(res))
}

const addPlanCategory = async (req) => {

    var dat = new FormData();
    for (const i in req) {
        dat.append(i, req[i]);
    }

    return await http.post(API_URL + '/config/category/add', dat,
        {
            headers: { ...authHeader(), "Content-Type": "multipart/form-data" },

        })

}
const fetchPlanCategory = async (id) => {

    return await http.get(API_URL + `/config/getCategories`,
        {
            headers: authHeader(),

        })
}
const fetchRecentUsers = async (id) => {

    return await http.get(API_URL + `/users/getAllUsers`,
        {
            headers: authHeader(),

        })
}
const deletePlanCategory = async (req) => {

    return await http.post(API_URL + `/config/category/delete`, req,
        {
            headers: authHeader(),

        })
}
const deleteSchemeCategory = async (req) => {

    return await http.post(API_URL + `/config/scheme/delete`, req,
        {
            headers: authHeader(),

        })
}
const planCategoryProject = async (req) => {

    return await http.post(API_URL + `/config/category/addProject`, req,
        {
            headers: authHeader(),

        })
}
const planCategoryScheme = async (req) => {

    return await http.post(API_URL + `/config/scheme/addProject`, req,
        {
            headers: authHeader(),

        })
}
const removeProjectFromScheme = async (req) => {

    return await http.post(API_URL + `/config/scheme/removeProject`, req,
        {
            headers: authHeader(),

        })
}
const removeProjectFromCategory = async (req) => {

    return await http.post(API_URL + `/config/category/removeProject`, req,
        {
            headers: authHeader(),

        })
}

const addScheme = async (req) => {

    var dat = new FormData();
    for (const i in req) {
        dat.append(i, req[i]);
    }

    return await http.post(API_URL + '/config/scheme/add', dat,
        {
            headers: { ...authHeader(), "Content-Type": "multipart/form-data" },

        })

}
const fetchScheme = async (id) => {

    return await http.get(API_URL + `/config/getSchemes`,
        {
            headers: authHeader(),

        })
}
const fetchMessages = async (req) => {

    return await http.post(API_URL + `/chat/getChatMessages`, req,
        {
            headers: authHeader(),

        })
}
const fetchChat = async (req) => {

    return await http.post(API_URL + `/chat/getUserChats`, req,
        {
            headers: authHeader(),

        })
}
const startChat = async (req) => {

    return await http.post(API_URL + `/chat/startChat`, req,
        {
            headers: authHeader(),

        })
}
const startChatGpp = async (req) => {

    return await http.post(API_URL + `/chat/startChatGlobal`, req,
        {
            headers: authHeader(),

        })
}
const messageSend = async (req) => {
    var dat = new FormData();
    for (const i in req) {
        dat.append(i, req[i]);
    }
    return await http.post(API_URL + `/chat/addChatMessage`, dat,
        {
            headers: authHeader(),

        })
}
const getAnalyticSchemes = async (id) => {

    return await http.get(API_URL + `/analytics/getScheme`,

        {
            headers: authHeader(),

        })
}
const setFav = async (req) => {

    return await http.post(API_URL + `/projects/setFavorite`, req,
        {
            headers: authHeader(),

        })
}
const updateCategory = async (req) => {
    var dat = new FormData();
    for (const i in req) {
        dat.append(i, req[i]);
    }

    return await http.post(API_URL + `/config/category/update`, dat,
        {
            headers: authHeader(),

        })
}
const updateScheme = async (req) => {
    var dat = new FormData();
    for (const i in req) {
        dat.append(i, req[i]);
    }

    return await http.post(API_URL + `/config/scheme/update`, dat,
        {
            headers: authHeader(),

        })
}
const getAllOpportunity = async (req) => {
    return await http.post(API_URL + `/projects/filterProjects`, req,
        {
            headers: authHeader(),

        })
}
const getProfile = async (req) => {
    return await http.post(API_URL + `/auth/getProfile `, req,
        {
            headers: authHeader(),

        })
}
const getUserProfile = async (req) => {
    return await http.post(API_URL + `/auth/getProfile `, req,
        {
            headers: authHeader(),

        })
}
const getUserById = async (type) => {
    return await http.get(API_URL + `/users/getAllUsers?type=${type}`,
        {
            headers: authHeader(),

        })
}
const updatePass = async (req) => {
    return await http.post(API_URL + `/auth/changePassword`, req,
        {
            headers: authHeader(),

        })
}

const userStatus = async (req) => {
    return await http.post(API_URL + `/auth/userStatus`, req,
        {
            headers: authHeader(),

        })
}
const addUsers = async (req) => {
    return await http.post(API_URL + `/auth/AddUser`, req,
        {
            headers: authHeader(),
        })
}
const forgotPassword = async (req) => {
    return await http.post(API_URL + `/auth/forgot`, req,
        {
            headers: authHeader(),
        })
}
const setforgotPassword = async (req, token) => {

    let header = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    };

    header['x-access-token'] = token;

    return await http.post(API_URL + `/auth/setPassword`, req,
        {
            headers: header
        })
}

const postReminders = async (req, emails) => {

    var data = new FormData();
    for (const i in req) {
        data.append(i, req[i]);
    }
    for (let i = 0; i < emails.length; i++) {
        data.append(`users[${i}]`, emails[i]);
    }

    return await http.post(API_URL + `/config/remainder`, data,
        {
            headers: { ...authHeader(), "Content-Type": "multipart/form-data" },

        })
}

const getFilterProjects = async (req) => {

    return await http.post(API_URL + `/searchProject`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}
const generateReport = async (req) => {

    return await http.post(API_URL + `/projectsReport`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}
const generateIvestorsReport = async (req) => {

    return await http.post(API_URL + `/investorsReport`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}


const postSectors = async (req) => {

    return await http.post(API_URL + `/config/addIncentives`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}

const postSubSectors = async (req) => {

    return await http.post(API_URL + `/config/subsectorBrief`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}

const addReport = async (req) => {

    var dat = new FormData();
    for (const i in req) {
        dat.append(i, req[i]);
    }

    return await http.post(API_URL + '/config/projectReportInfo', dat,
        {
            headers: { ...authHeader(), "Content-Type": "multipart/form-data" },

        })

}
const sectorProject = async (req) => {

    return await http.post(API_URL + `/sectorProjects`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}
const fetIncentives = async (req) => {

    return await http.post(API_URL + `/sectorIncentives`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}
const fetchReportInfo = async () => {

    return await http.get(API_URL + `/lookup/report`,
    ).then((response) => {

        if (response.status !== '200') {
            return {}
        }
        return response;
    });
}
const fetchCompanies = async (req) => {

    return await http.post(API_URL + `/project/companies`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}
const addtoBlacklist = async (req) => {

    return await http.post(API_URL + `/project/company/addToBlacklist`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}
const updateBlacklist = async (req) => {

    return await http.post(API_URL + `/config/update/blacklist`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}

const addTestimonial = async (req) => {

    return await http.post(API_URL + `/config/add/testimonial`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}


const getTestimonials = async (req) => {

    return await http.post(API_URL + `/config/get/testimonial`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}



const updateTestimonials = async (req) => {

    return await http.post(API_URL + `/config/update/testimonial`, req,
        {
            headers: authHeader(),

        }).then((response) => {

            if (response.status !== '200') {
                return {}
            }
            return response;
        });
}


const deleteTestimonial = async (req) => {

    return await http.post(API_URL + `/config/delete/testimonial`, req,
        {
            headers: authHeader(),

        })
}
const fetchSchemeProjects = async (req) => {

    return await http.post(API_URL + `/schemeProjects`, req,
        {
            headers: authHeader(),

        })
}

export {
    fetchInvestModelData,
    fetchSectorsData,
    fetchInvestTypeData,
    getSubSectors,
    getSectors,
    geProjectSize,
    geCountries,
    fetchProvinceStats,
    fetchSectorDetails,
    getprojectLocation,
    getLocationDistrict,
    getFeasibilityStatus,
    uploadProject,
    getDevelopmentGoals,
    fetchProject,
    fetchProjects,
    fetchProjectDetail,
    ProjectViewInc,
    UploadFile,
    updateProjectStatus,
    updateMarqueeStatus,
    addScheme,
    addPlanCategory,
    fetchPlanCategory,
    fetchScheme,
    deletePlanCategory,
    planCategoryProject,
    deleteSchemeCategory,
    planCategoryScheme,
    updateCategory,
    updateScheme,
    fetchRecentUsers,
    setFav,
    fetchSingleProject,
    getSubSectorsDataById,
    getAllOpportunity,
    getProfile,
    fetchChat,
    startChat,
    fetchMessages,
    messageSend,
    getUserById,
    getAnalyticSchemes,
    startChatGpp,
    getUserProfile,
    updatePass,
    fetchProvinceStatsGpp,
    userStatus,
    addUsers,
    forgotPassword,
    setforgotPassword,
    postReminders,
    verifyAccount,
    getFilterProjects,
    getPrivateProjectTypes,
    getPrivateProjectCategory,
    generateReport,
    postSectors,
    addReport,
    sectorProject,
    fetIncentives,
    fetchReportInfo,
    postSubSectors,
    fetchCompanies,
    addtoBlacklist,
    generateIvestorsReport,
    removeProjectFromScheme,
    removeProjectFromCategory,
    updateBlacklist,
    addTestimonial,
    getTestimonials,
    updateTestimonials,
    deleteTestimonial,
    fetchSchemeProjects
}