import "./App.less";
import React, { Fragment, useEffect, useState } from "react";
import MainRouter from "./router/appRouter";
import { Alert, Spin } from "antd";
import ErrorBoundary from "./common/ErrorBoundary";
import { useSelector } from "react-redux";
import Chat from "./common/chat/chat";
import { Link } from "react-router-dom";

const App = () => {
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  //  const translate = require("baidu-translate-api");

  useEffect(() => {
    const spinner = document.getElementById("pre-loader-spinner");
    if (spinner) {
      setTimeout(() => {
        spinner.style.display = "none";
        setLoading(false);
      }, 2000);
    }
    
  }, []);

  return (
    !loading && (
      <Fragment>
        <ErrorBoundary>
          <div id="loader-wrapper">
            <div id="skeleton">
              <Spin size="large" />
            </div>
          </div>
          <MainRouter />
          {auth?.isLoggedIn &&
            auth?.user?.data?.roleName?.toLowerCase() !== "investor" && (
              <Chat />
            )}
          {auth?.isLoggedIn &&
            auth?.user?.data?.roleName?.toLowerCase() === "investor" &&
            auth?.user?.data?.registerStep >= 3 && <Chat />}
          {auth?.isLoggedIn &&
            auth?.user?.data?.registerStep < 3 &&
            auth?.user?.data?.roleName?.toLowerCase() === "investor" && (
              <div className="complete-profile-alert">
                <Alert
                  message={
                    <Link to="/investor-profile">
                      Please Complete Your Profile
                    </Link>
                  }
                  type="info"
                  showIcon
                />
              </div>
            )}
        </ErrorBoundary>
      </Fragment>
    )
  );
};

export default App;
