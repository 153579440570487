import { combineReducers } from "redux";
import auth from "./auth";
import project from "./project";
import message from "./message";
import config from "./config";
import sectorDetails from "./sectorDetails";
import global from "./global";

export default combineReducers({
  auth,
  project,
  message,
  config,
  sectorDetails,
  global
});