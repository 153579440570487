import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_NAME_UPDATE
} from "../actions/types";
// import { loadState } from "../store/browser-storage";
// const user = JSON.parse(localStorage.getItem("user"));
import aes256 from "aes256";
const KEY = process.env.REACT_APP_HASH_KEY;
const initialState = {
  // ...loadState()
};
// const initialState = user
//   ? { isLoggedIn: true, user }
//   : { isLoggedIn: false, user: null };

export default function auth(state = initialState, action) {

  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };

    case USER_NAME_UPDATE:
      return {
        ...state,
        user: payload.user
      }

    case LOGIN_SUCCESS:

      let obj = {
        ...state,
        isLoggedIn: true,
        user: payload.user
      }
      let storeData = JSON.stringify(obj);
      storeData = btoa(unescape(encodeURIComponent(storeData)));
      let cipher = aes256.createCipher(KEY);
      let encrypted = cipher.encrypt(storeData);
      localStorage.setItem("auth", encrypted);

      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      localStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}