import {
    GET_ROLES_SUCCESS,
    GET_ROLES_FAIL,GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAIL,
    GET_SECTOR_SUCCESS,
    GET_SECTOR_FAIL,
    GET_PLAN_CATEGORIES_SUCCESS,
    GET_PLAN_CATEGORIES_FAIL,
    GET_ALL_SECTOR_FAIL,
    GET_ALL_SECTOR_SUCCESS,
    GET_CHAT_USER_FAIL,
    GET_CHAT_USER_SUCCESS,
    GET_TESTIMONIAL_FAIL,
    GET_TESTIMONIAL_SUCCESS
  } from "../actions/types";


  const initialState = {};

  export default function global(state = initialState, action) {

    const { type, payload } = action;
  
    switch (type) {
      
      case GET_TESTIMONIAL_SUCCESS:
        
        return {
          ...state,
          testimonials: payload.testimonials,
        };
      case GET_TESTIMONIAL_FAIL:
        return {
            ...state,
            testimonials: {},
        };
      case GET_SECTOR_SUCCESS:
        
        return {
          ...state,
          sectors: payload.sectors,
        };
      case GET_SECTOR_FAIL:
        return {
            ...state,
            sectors: {},
        };
      case GET_ALL_SECTOR_SUCCESS:
        
        return {
          ...state,
          allSectors: payload.allSectors,
        };
      case GET_ALL_SECTOR_FAIL:
        return {
            ...state,
            allSectors: {},
        };
      case GET_ROLES_SUCCESS:
        return {
          ...state,
          roles: payload.roles,
        };
      case GET_ROLES_FAIL:
        return {
            ...state,
            roles: {},
        };
      case GET_COUNTRY_SUCCESS:
        return {
          ...state,
          country: payload.country,
        };
      case GET_COUNTRY_FAIL:
        return {
            ...state,
            country: {},
        };
      case GET_PLAN_CATEGORIES_SUCCESS:
        return {
          ...state,
          categories: payload.categories,
        };
      case GET_PLAN_CATEGORIES_FAIL:
        return {
            ...state,
            categories: {},
        };
      case GET_CHAT_USER_SUCCESS:
        return {
          ...state,
          chatUsers: payload.chatUsers,
        };
      case GET_CHAT_USER_FAIL:
        return {
            ...state,
            chatUsers: [],
        };
      case "CHAT_ID":
        
        return {
            ...state,
            chatId: payload,
        };
      default:
        return state;
    }
  }