import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import { loadState } from "./browser-storage";

const middleware = [thunk];

let obj = {
  auth: loadState()
}

const store = createStore(
  rootReducer,
  obj,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;