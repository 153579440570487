import { Divider, Empty } from 'antd';
import React, { Fragment } from 'react'
import { IoClose } from 'react-icons/io5';
import { getWord } from '../../helpers/globalFunctions';
import { motion } from 'framer-motion';
import { chatVariant } from '../../animate/animations';
const ChatUsersList = ({ open, closeChatBox, setSelectedUser, getMessages, users }) => {

  return (

    <Fragment>
      {open ?
        <motion.div className="chat-users-lists" initial={"hidden"}
          animate={"visible"} variants={chatVariant}>
          <div style={{ position: 'absolute', right: 6, top: 6, cursor: 'pointer' }}
            onClick={() => closeChatBox()}
          ><IoClose size={25} /></div>
          {users && users?.length > 0 && users.map((user, index) => (
            <div key={index}>
              <div className="d-flex align-items-center" onClick={() => {
                setSelectedUser(user?.companyFullName ? user?.companyFullName : `${user?.companyFirstName} ${user?.companyLastName}`);
                getMessages(user?.chatId)
              }
              }>

                <div className="chat-list-avatar">
                  <div className="chat-list-avatar-letters">
                    {getWord(
                      user?.companyFullName ? user?.companyFullName : `${user?.companyFirstName} ${user?.companyLastName}`
                    )}
                  </div>
                </div>
                <div className="chat-users-list-bio ms-3">

                  <p className="mb-0 text-capitalize">

                    {/* <p className="mb-0 text-capitalize" onClick={() => {
                    setSelectedUser(user?.companyFullName ? user?.companyFullName : `${user?.companyFirstName} ${user?.companyLastName}`);
                    getMessages(user?.chatId)
                  }
                  }> */}

                    {user?.companyFullName ? user?.companyFullName : `${user?.companyFirstName} ${user?.companyLastName}`}

                  </p>
                </div>
              </div>
              {index !== users?.length && <Divider className="m-2" />}
            </div>
          ))}
          {users && users?.length === 0 && <div>
            <Empty description={"No Active Chats"} />
          </div>}
        </motion.div>
        : null}
    </Fragment>


  )
}

export default ChatUsersList;



