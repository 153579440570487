export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL";

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const GET_PLAN_CATEGORIES_SUCCESS = "GET_PLAN_CATEGORIES_SUCCESS";
export const GET_PLAN_CATEGORIES_FAIL = "GET_PLAN_CATEGORIES_FAIL";

export const GET_DASHBOARD_PROJECTS_SUCCESS = "GET_DASHBOARD_PROJECTS_SUCCESS";
export const GET_DASHBOARD_PROJECTS_FAIL = "GET_DASHBOARD_PROJECTS_FAIL";

export const GET_PLAN_CATEGORY_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PLAN_CATEGORY_FAIL = "GET_PROJECTS_FAIL";

export const GET_SCHEME_SUCCESS = "GET_SCHEME_SUCCESS";
export const GET_SCHEME_FAIL = "GET_SCHEME_FAIL";
export const GET_FAV_SUCCESS = "GET_FAV_SUCCESS";
export const GET_FAV_FAIL = "GET_FAV_FAIL";

export const GET_CONFIGURATION_SUCCESS = "GET_CONFIGURATION_SUCCESS";
export const GET_CONFIGURATION_FAIL = "GET_CONFIGURATION_FAIL";

export const GET_SECTOR_DETAILS_SUCCESS = "GET_SECTOR_DETAILS_SUCCESS";
export const GET_SECTOR_DETAILS_FAIL = "GET_SECTOR_DETAILS_FAIL";

export const GET_CHAT_USER_SUCCESS = "GET_CHAT_USER_SUCCESS";
export const GET_CHAT_USER_FAIL = "GET_CHAT_USER_FAIL";

export const GET_ALL_SECTOR_SUCCESS = "GET_ALL_SECTOR_SUCCESS";
export const GET_ALL_SECTOR_FAIL = "GET_SECTOR_DETAILS_FAIL";
export const GET_BLACKLIST_SUCCESS = "GET_BLACKLIST_SUCCESS";
export const GET_BLACKLIST_FAIL = "GET_BLACKLIST_FAIL";

export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "FAIL";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_FAIL = "FAIL";
export const GET_SECTOR_SUCCESS = "GET_SECTOR_SUCCESS";
export const GET_SECTOR_FAIL = "FAIL";

export const GET_PROVINCE_SUCCESS = "GET_PROVINCE_SUCCESS";
export const GET_STATS_SUCCESS = "GET_STATA_SUCCESS";
export const GET_STATS_FAIL = "GET_STATA_FAIL";
export const GET_PROVINCE_FAIL = "FAIL";
export const GET_TESTIMONIAL_SUCCESS = "GET_TESTIMONIAL_SUCCESS";
export const GET_TESTIMONIAL_FAIL = "GET_TESTIMONIAL_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const USER_NAME_UPDATE = "USER_NAME";