import {
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROVINCE_SUCCESS,
  GET_PROVINCE_FAIL,
  GET_STATS_SUCCESS,
  GET_STATS_FAIL,
  GET_PLAN_CATEGORY_SUCCESS,
  GET_PLAN_CATEGORY_FAIL,
  GET_SCHEME_SUCCESS,
  GET_SCHEME_FAIL,
  GET_FAV_FAIL,
  GET_FAV_SUCCESS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_DASHBOARD_PROJECTS_FAIL,
  GET_DASHBOARD_PROJECTS_SUCCESS,
  GET_BLACKLIST_SUCCESS,
  GET_BLACKLIST_FAIL
} from "../actions/types";


const initialState = {};

export default function project(state = initialState, action) {

  const { type, payload } = action;
  switch (type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: payload.project?.data,
      };
    case GET_PROJECTS_FAIL:
      return {
        ...state,
        projects: {},
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: payload.users.data,
      };
    case GET_USERS_FAIL:
      return {
        ...state,
        users: {},
      };
    case GET_FAV_SUCCESS:
     
      return {
        ...state,
        fav: payload.fav,
      };
    case GET_FAV_FAIL:
      return {
        ...state,
        fav: {},
      };
    case GET_PLAN_CATEGORY_SUCCESS:
      return {
        ...state,
        projects: payload.planCategories.data,
      };
    case GET_PLAN_CATEGORY_FAIL:
      return {
        ...state,
        projects: {},
      };
    case GET_DASHBOARD_PROJECTS_SUCCESS:
      
      return {
        ...state,
        dashboardProjects: payload.dashboardProjects,
      };
    case GET_DASHBOARD_PROJECTS_FAIL:
      return {
        ...state,
        dashboardProjects: {},
      };
    case GET_SCHEME_SUCCESS:
      
      return {
        ...state,
        schemes: payload.schemes,
      };
    case GET_SCHEME_FAIL:
      return {
        ...state,
        schemes: {},
      };
    case GET_STATS_SUCCESS:
      
      return {
        ...state,
        stats: payload.stats,
      };
    case GET_STATS_FAIL:
      return {
        ...state,
        stats: {},
      };
    case GET_PROVINCE_SUCCESS:
      return {
        ...state,
        province: payload.province,
      };
    case GET_PROVINCE_FAIL:
      return {
        ...state,
        province: {},
      };
    case GET_BLACKLIST_SUCCESS:
      return {
        ...state,
        blacklist: payload.blacklist,
      };
    case GET_BLACKLIST_FAIL:
      return {
        ...state,
        blacklist: [],
      };
    default:
      return state;
  }
}