import aes256 from "aes256";

const KEY = process.env.REACT_APP_HASH_KEY;
var cipher = aes256.createCipher(KEY);


export function loadState() {
  try {

    const serializedState = localStorage.getItem('auth');
    let decrypted = cipher.decrypt(serializedState);


    if (!serializedState) return undefined;

    let auth = JSON.parse(decodeURIComponent(escape(atob(decrypted))));

    return auth
  } catch (e) {
    return undefined;
  }
}

export async function saveState(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    // Ignore
  }
}

