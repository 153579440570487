import moment from 'moment';

const getWord = (name) => {
    var parts = name.split(' ');
    var initials = ' ';
    for (var i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== '') {
            initials += parts[i][0]
        }
    }
    return initials.toUpperCase();
}
const toCamel = (s) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
};
const getLength = (array, type) => {
    let length = array?.filter(i => i?.type === type).map(i => i?.value?.length);
    return +length || 0
};

const getEquityInvestorFormKeys = (obj) => {

    let equityInvestorForm = [
        'companyBriefIntro', 'companyExperience', 'companyNtn',
        'companyWebaddress', 'contactNumberMobile', 'contactNumberOffice',
        'contactPersonProvince', 'designation', 'email', 'firstName',
        'govtReqApprovals', 'isCompanyEstablished', 'landAcquired',
        'landStatus', 'lastName', 'parentCompany', 'personTitle',
        'pojectFeasibilityStatus', 'privateProjectType',
        'projectBriefIntro', 'projectCompanyName', 'projectCostBreakup',
        'projectDistrict', 'projectEstimatedCompletionTime',
        'projectEstimatedCost', 'projectLocation', 'projectName',
        'projectOtherDetail', 'projectProfitability', 'projectSectorId',
        'projectSubsectorId', 'revenueGeneration', 'secpRegistrar',
        'seekingInvestment', 'sourceOfFund', 'status', 'whoIam'];
    let filterKeys = {};

    equityInvestorForm.forEach((val, index) => {
        filterKeys = { ...filterKeys, [`${val}`]: obj[val] }
    })
    filterKeys.projectLocation = obj.provinceId;
    filterKeys.seekingInvestment = obj.seekingInvestment.split("," || []);
    filterKeys.projectDistrict = obj.projectDistrict.toString();
    filterKeys.landStatus = obj.landAvailabilityOrRequirementOrStatus;
    filterKeys.landAcquired = obj.landAcquired;
    filterKeys.pojectFeasibilityStatus = obj.projectFeasibilityOrPreFeasibilityStatus;
    filterKeys.govtReqApprovals = obj.requiredApprovalsFromGovtDepartments;
    filterKeys.personTitle = obj.contactPersonTitle;
    filterKeys.projectId = obj?.projectId || "";
    filterKeys.secpRegistrar = obj?.companySecpRegistrar;


    //    For Files 


    if (obj?.files?.length > 0) {

        let profileArray = obj.files.filter(i => i.file_for === "PROJECT_IMAGE");
        let companyArray = obj.files.filter(i => i.file_for === "COMPANY_IMAGE");
        let portArray = obj.files.filter(i => i.file_for === "COMPANY_PORTFOLIO");

        // project image
        let profImg = profileArray?.length > 0 && profileArray?.reduce(function (preVal, currentVal) {

            if (moment(preVal?.created_at).unix() > moment(currentVal?.created_at).unix()) {

                return preVal
            }
            else {

                return currentVal
            }
        })
        filterKeys.projectImage = profImg?.file_original_name || "";

        // company image
        let compImg = companyArray?.length > 0 && companyArray?.reduce(function (preVal, currentVal) {

            if (moment(preVal?.created_at).unix() > moment(currentVal?.created_at).unix()) {

                return preVal
            }
            else {

                return currentVal
            }
        })
        filterKeys.companyImage = compImg?.file_original_name || "";
        // portfolio image
        let portImg = portArray?.length > 0 && portArray?.reduce(function (preVal, currentVal) {

            if (moment(preVal?.created_at).unix() > moment(currentVal?.created_at).unix()) {

                return preVal
            }
            else {

                return currentVal
            }
        })
        filterKeys.companyPortfolio = portImg?.file_original_name || "";
    }


    return filterKeys;
}
const startupformValues= (obj) => {
    
   

    let startupFormKeys = [
        'whoIam', 'privateProjectType', 'projectName',
        'startupLogo', 'projectBriefIntro', 'startupMulti', 'startupPitch',
        'projectLocation', 'projectDistrict', 'projectSectorId', 'projectSubsectorId',
        'startupStage', 'problemStatement', 'marketSize', 'targetMarket',
        'seekingInvestment', 'projectEstimatedCost',
        'fundNegotiable', 'projectCostBreakup', 'isStartupGeneratingRevenue', 'startupRevenueGeneration',
        'pojectFeasibilityStatus', 'govtReqApprovals',
        'projectOtherDetail', 'startupRegistration', 'projectCompanyName', 'visitUs',
        'socialMedia', 'secpRegistrar', 'companyNtn', 'startupBankAccount', 'teamSize', 'femaleCofounder',
        'teamDetails', 'personTitle', 'firstName', 'lastName', 'designation', 'email', 'contactNumberOffice', 'contactNumberMobile',
    ];
    let filterKeys = {};

    startupFormKeys.forEach((val, index) => {
        filterKeys = { ...filterKeys, [`${val}`]: obj[val] }
    });
    
    filterKeys.projectLocation = obj.provinceId;
    filterKeys.seekingInvestment = obj.seekingInvestment?.split("," || []);
    filterKeys.projectDistrict = obj.projectDistrict?.toString();
    filterKeys.startupRevenueGeneration = obj.startupRevenueGeneration?.split(",") ;
    filterKeys.pojectFeasibilityStatus = obj.projectFeasibilityOrPreFeasibilityStatus?.split(",");
    filterKeys.govtReqApprovals = obj.requiredApprovalsFromGovtDepartments;
    filterKeys.personTitle = obj.contactPersonTitle;
    filterKeys.projectId = obj?.projectId || "";
    filterKeys.secpRegistrar = obj?.companySecpRegistrar;
    filterKeys.socialMedia = obj?.socialMedia;
    filterKeys.teamDetails = obj?.teamDetails;
    filterKeys.visitUs = obj?.visitUs;
    filterKeys.whoIam =obj.whoIam;
    filterKeys.privateProjectType =obj.privateProjectType



    if (obj?.files?.length > 0) {

        let profileArray = obj.files.filter(i => i.file_for === "STARTUP_PITCH");
        let startupMultiArray = obj.files.filter(i => i.file_for === "STARTUP_MULTI");
        let portLogo = obj.files.filter(i => i.file_for === "STARTUP_LOGO");

        // project image
        let profImg = profileArray?.length > 0 && profileArray?.reduce(function (preVal, currentVal) {

            if (moment(preVal?.created_at).unix() > moment(currentVal?.created_at).unix()) {

                return preVal
            }
            else {

                return currentVal
            }
        })
        filterKeys.startupPitch = profImg?.file_original_name || "";

        // Logo image

        let logoImg = portLogo?.length > 0 && portLogo?.reduce(function (preVal, currentVal) {

            if (moment(preVal?.created_at).unix() > moment(currentVal?.created_at).unix()) {

                return preVal
            }
            else {

                return currentVal
            }
        })
        filterKeys.startupLogo = logoImg?.file_original_name || "";

        // Mulitple images
        startupMultiArray.forEach(element => {
            element.name = element.file_original_name
        });
        filterKeys.startupMulti = startupMultiArray;


    }

    return filterKeys;
}
const donorFormValues = (obj) => {
   

    let donorFormKeys = [
        "areYou", "companyMulti", "establishmentDate", "founderName",
        "organizationName", "previousProjectsUndertaken", "privateProjectCategory",
        "privateProjectType", "projectBriefIntro", "projectEstimatedCompletionTime",
        "projectEstimatedCost", "projectExpectedOutcomes", "projectId",
        "projectImage", "projectName", "projectObjectives", "projectOtherDetail",
        "projectSectorId", "projectSubsectorId", "projectThematicFocus",
        "registrationDetails", "whoIam", "contactNumberMobile", "contactNumberOffice",
        "contactPersonProvince", "designation", "email", "firstName",
    ];
    let filterKeys = {};

    donorFormKeys.forEach((val, index) => {
        filterKeys = { ...filterKeys, [`${val}`]: obj[val] }
    });

    filterKeys.founderName = obj.organizationFounderName;
    filterKeys.establishmentDate = moment(obj.organizationEstablishmentDate);
    filterKeys.registrationDetails = obj.organizationRegistrationDetails;
    filterKeys.personTitle = obj.contactPersonTitle
    filterKeys.firstName = obj.firstName
    filterKeys.lastName = obj.lastName
    filterKeys.lastName = obj.lastName
    // filterKeys.companyMulti = [
    //     {
    //       uid: '1',
    //       name: 'xxx.png',
    //       status: 'done',
    //       response: 'Server Error 500', // custom error message to show
    //       url: 'http://www.baidu.com/xxx.png',
    //     },
    // ]

    filterKeys.projectId = obj?.projectId || "";
    if (obj?.files?.length > 0) {

        let profileArray = obj.files.filter(i => i.file_for === "PROJECT_IMAGE");
        let companyMultiArray = obj.files.filter(i => i.file_for === "COMPANY_MULTI");
        // let portArray = obj.files.filter(i => i.file_for === "COMPANY_PORTFOLIO");

        // project image
        let profImg = profileArray?.length > 0 && profileArray?.reduce(function (preVal, currentVal) {

            if (moment(preVal?.created_at).unix() > moment(currentVal?.created_at).unix()) {

                return preVal
            }
            else {

                return currentVal
            }
        })
        filterKeys.projectImage = profImg?.file_original_name || "";

        // Mulitple images
        companyMultiArray.forEach(element => {
            element.name = element.file_original_name
        });
        filterKeys.companyMulti = companyMultiArray;


    }

    return filterKeys;
}

export {
    getWord, toCamel, getLength, getEquityInvestorFormKeys,
    donorFormValues, startupformValues
}


