import {
    GET_SECTOR_DETAILS_SUCCESS,
    GET_SECTOR_DETAILS_FAIL,
  } from "../actions/types";


  const initialState = {};

  export default function sectorDetails(state = initialState, action) {

    const { type, payload } = action;
    switch (type) {
      case GET_SECTOR_DETAILS_SUCCESS:
        if(payload.data && payload.data[0].sectorName) {        
          return {
            ...state,
            sectors:payload.data
          };
        }
        else {
            return {
                ...state,
                province:payload.data
              };
        }
      case GET_SECTOR_DETAILS_FAIL:
        return {
            ...state,
        };
      default:
        return state;
    }
  }